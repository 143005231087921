import styled from 'styled-components';

export const LegalInfo = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const CardFeesTable = styled.table`
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.brand.text};
  margin: 0 auto;

  th,
  td {
    border: 1px solid ${({ theme }) => theme.brand.text};
  }

  th,
  td {
    padding: 10px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    width: 75%;
  `}
`;
