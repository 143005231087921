import React from 'react';
// Import Global Components
import Title from '../../../components/Title';
import Text from '../../../components/Text';
// Import Layouts
import Main from '../../../layouts/Main';
// Import Translations
import { verifyEn } from '../../../i18n/verify';

export default () => (
  <Main>
    <Title
      title={verifyEn['verify-invalidLink-title']}
      titleFor="invalidLink"
      page="verify"
    />
    <Text
      text={verifyEn['verify-invalidLink-text']}
      textFor="invalidLink"
      page="verify"
    />
  </Main>
);
