import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Text } from './styles';

const TextWrapper = ({ page, text, textFor, ...props }) => (
  <Text>
    <FormattedMessage
      id={`${page}-${textFor}-text`}
      description={`Text for ${textFor}`}
      defaultMessage={text}
      {...props}
    />
  </Text>
);

TextWrapper.propTypes = {
  text: PropTypes.string.isRequired,
  textFor: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
};
 
export default TextWrapper;