import styled from 'styled-components';

// Styles the body of the entire web page
export const BaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) =>
    theme.brand.background.includes('https')
      ? `background: url(${theme.brand.background});
       background-size: cover;
    `
      : `background: ${theme.brand.background};`}
  background-size: cover;
  position: relative;
  overflow: auto;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  padding: 20px 20px 0 20px;
`;

// positions header, body, and footer on the web page
export const Base = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1405px;
  min-width: 545px;
  width: calc(100% - 15px);

  & > *:not(:first-child) {
    margin-top: 10px;
  }
`;
