import React from 'react';
import PropTypes from 'prop-types';
// Import Global Components
import Main from '../../components/Main';
import SectionOne from './components/SectionOne';
import VirtualCardInfo from './components/VirtualCardInfo';
// Import Translations
import { activatedEn } from '../../i18n/activated';

const Activated = ({ history, location }) => {
  // Pull CardDetails from Location State
  const { virtualCardDetails } = location.state;

  return (
    <Main
      title={activatedEn['activated-main-title']}
      page="activated"
      sectionOne={(
        <SectionOne
          number={virtualCardDetails.card_number}
          name={virtualCardDetails.cardholder_name}
          expiry={virtualCardDetails.expiry_date}
          cardSecurityCode={virtualCardDetails.card_security_code}
          address={virtualCardDetails.address}
        />
      )}
      sectionTwo={(
        <VirtualCardInfo registerLink={virtualCardDetails.register_card_link} />
      )}
      history={history}
    />
  );
};

Activated.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired
};

export default Activated;