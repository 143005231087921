import React from 'react';
import { Card } from 'shared-components';

import TCCA from './TCCA';

export default () => (
  <Card>
    <TCCA />
  </Card>
);
