import styled from 'styled-components';

export const NumberListItem = styled.li`
  list-style: number;
  margin-left: 15px;
`;

export const BulletListItem = styled.li`
  list-style: disc;
  margin: 0 50px;
`;

export const LegalInfo = styled.div`
  & > *:not(:last-child),
  ol > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;
