import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Global Components
import { Button } from 'shared-components';
import Text from '../../../components/Text';
// Import Component Styles
import { VirtualCardSection, ListItem } from '../styles';
// Import Translations
import { activatedEn } from '../../../i18n/activated';

const VirtualCardInfo = ({ registerLink }) => (
  <VirtualCardSection>
    <div>
      <div>
        <Text
          page="activated"
          text={activatedEn['activated-sectionTwo-p1-text']}
          textFor="sectionTwo-p1"
        />
      </div>
      <div>
        <Text
          page="activated"
          text={activatedEn['activated-sectionTwo-p2-text']}
          textFor="sectionTwo-p2"
        />
        <ul>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li1-text']}
              textFor="sectionTwo-li1"
            />
          </ListItem>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li2-text']}
              textFor="sectionTwo-li2"
            />
          </ListItem>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li3-text']}
              textFor="sectionTwo-li3"
            />
          </ListItem>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li4-text']}
              textFor="sectionTwo-li4"
            />
          </ListItem>
        </ul>
      </div>
      <div>
        <Text
          page="activated"
          text={activatedEn['activated-sectionTwo-p4-text']}
          textFor="sectionTwo-p4"
        />
        <ul>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li5-text']}
              textFor="sectionTwo-li5"
            />
          </ListItem>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li6-text']}
              textFor="sectionTwo-li6"
            />
          </ListItem>
          <ListItem>
            <Text
              page="activated"
              text={activatedEn['activated-sectionTwo-li7-text']}
              textFor="sectionTwo-li7"
            />
          </ListItem>
        </ul>
      </div>
    </div>
    <div>
      <Button
        type="primary"
        variant="text"
        onClick={() => window.print()}
      >
        <FormattedMessage
          id="activated-print-button"
          description="Button for print"
          defaultMessage={activatedEn['activated-print-button']}
        />
      </Button>
      <Button
        type="primary"
        size="large"
        onClick={() => window.open(registerLink)}
      >
        <FormattedMessage
          id="activated-register-button"
          description="Button for register"
          defaultMessage={activatedEn['activated-register-button']}
        />
      </Button>
    </div>
  </VirtualCardSection>
);

VirtualCardInfo.propTypes = {
  registerLink: PropTypes.string.isRequired
};
 
export default VirtualCardInfo;
