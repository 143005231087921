import React from 'react';
// Import Shared Components
import { VirtualCard, Label } from 'shared-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Component Styles
import { CardInfo } from './styles';

const SectionOne = ({ page, label, children, ...props }) => (
  <>
    <VirtualCard {...props} />
    <CardInfo>
      <Label htmlFor="sectionOne">
        <FormattedMessage
          id={`${page}-sectionOne-label`}
          description={`Label for ${label}`}
          defaultMessage={`${label}`}
        />
      </Label>
      {children}
    </CardInfo>
  </>
);

SectionOne.propTypes = {
  children: PropTypes.element.isRequired,
  page: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default SectionOne;
