import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
// Import Shared Components
import { ThemeProvider, ErrorComponent } from 'shared-components';
import { initializeGlobalErrorHandler } from 'shared-components/src/utils/errors';
import useWhiteLabeling from 'shared-components/src/hooks/useWhiteLabeling';
// Import Constants
import { PRODUCTION } from 'shared-components/src/constants/nodeEnv';
// Import Translations
import TRANSLATIONS from './i18n/translations';
// Import Root Component
import Root from './routes/Root';

// Error Logging
import { errorLogger } from './utils/errors';

// White label theming

// Attach Error Logger to Window to Catch Global Errors
if (process.env.NODE_ENV === PRODUCTION) {
  initializeGlobalErrorHandler(errorLogger);
}

export default () => {
  const [locale, setLocale] = useState('en');

  // Retreive white labeling for app
  const { loading, theme, localization, error } = useWhiteLabeling();

  if (loading) return <div />;

  if (error) return <ErrorComponent />;

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      defaultLocale="en"
      messages={TRANSLATIONS[locale]}
    >
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Root setLocale={setLocale} locale={locale} localization={localization} />
        </ThemeProvider>
      </BrowserRouter>
    </IntlProvider>
  );
};
