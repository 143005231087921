import React from 'react';
import { FormattedMessage } from 'react-intl';
import { issuerStatementEn } from 'shared-components/src/components/IssuerStatement/text';
import { IssuerStatement, Footer } from 'shared-components';

export default () => (
  <Footer 
    legalStatements={[
      <IssuerStatement key="1">
        <FormattedMessage
          id="issuer-statement-p1-text"
          description="First paragraph of issuer statment"
          defaultMessage={issuerStatementEn['issuer-statement-p1-text']}
        />
      </IssuerStatement>,
      <IssuerStatement key="2">
        <FormattedMessage
          id="issuer-statement-p2-text"
          description="Second paragraph of issuer statment"
          defaultMessage={issuerStatementEn['issuer-statement-p2-text']}
        />
      </IssuerStatement>,
      <IssuerStatement key="3">
        <FormattedMessage
          id="issuer-statement-p3-text"
          description="Third paragraph of issuer statment"
          defaultMessage={issuerStatementEn['issuer-statement-p3-text']}
        />
      </IssuerStatement>
      
    ]}
  />
);
