import styled from 'styled-components';
import {Modal as UnstyledModal,Alert as UnstyledAlert,Button as UnstyledButton} from 'shared-components';


export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`;

export const FormGroup = styled.fieldset`
  border: 0;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: 10px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;

    & > *:not(:first-child) {
      margin-left: 10px;
      margin-top: 0;
    }
  `}
`;

export const RedText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
  margin: 13px 0 5px 0;
`;

export const LargeText = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

export const StyledModal = styled(UnstyledModal)`
  width: 40%; 
  max-width: 400px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledAlert = styled(UnstyledAlert)`
  overflow-wrap: break-word;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledButton = styled(UnstyledButton)`
  margin-left: 20px;
  margin-right: 20px;
`;

