import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Global Components
import { Label } from 'shared-components';
// Import Component Styles
import { CardInfo } from '../styles';

const CardInfoWrapper = ({ labelFor, label, info }) => (
  <CardInfo>
    <Label htmlFor={labelFor}>
      <FormattedMessage
        id={`activated-${labelFor}-${label}`}
        description={`Label for ${label}`}
        defaultMessage={`${label}`}
      />
    </Label>
    <p id={labelFor}>{info}</p>
  </CardInfo>
);

CardInfoWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  labelFor: PropTypes.string.isRequired,
  info: PropTypes.element.isRequired
};
 
export default CardInfoWrapper;