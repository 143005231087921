import React from 'react';
// Import Global Components
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Alert } from 'shared-components';
import Title from '../Title';
import Text from '../Text';
// Import Layouts
import Main from '../../layouts/Main';
// Import Component Styles
import { Sections, TitleContainer, SectionOne, SectionTwo } from './styles';

const VCMain = ({ title, subTitle, page, error, sectionOne, sectionTwo }) => (
  <Main>
    {error && (
      <Alert type="danger">
        <FormattedMessage
          id={`${page}-form-error-alert`}
          description="Alert for form-error"
          defaultMessage={error}
        />
      </Alert>
    )}
    {subTitle ? (
      <TitleContainer>
        <Title title={title} titleFor="main" page={page}>
          {title}
        </Title>
        <Text text={subTitle} textFor="main" page={page} />
      </TitleContainer>
    ) : (
      <Title title={title} titleFor="main" page={page}>
        {title}
      </Title>
    )}
    <Sections>
      <SectionOne>{sectionOne}</SectionOne>
      <SectionTwo>{sectionTwo}</SectionTwo>
    </Sections>
  </Main>
);

VCMain.propTypes = {
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  error: PropTypes.element,
  sectionOne: PropTypes.element.isRequired,
  sectionTwo: PropTypes.element.isRequired
};

VCMain.defaultProps = {
  error: null
};

export default VCMain;
