import styled from 'styled-components';

export const TitleContainer = styled.div`
  h3 {
    margin-bottom: 5px;
  }
  margin-bottom: 20px;
`;

export const Sections = styled.div`
  display: flex
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  align-content: stretch;

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;
  `}
`;

export const SectionOne = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-bottom: 20px;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 260px;
    margin-bottom: 0;
  `}

  ${({ theme }) => theme.media.desktopUp`
    max-width: 285px;
  `}
`;

export const SectionTwo = styled.section`
  display: flex;
  flex: 1;
  margin-left: 0;

  input[type="submit"] {
    width: 100%;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    margin-left: 20px;
  `}
`;
