import React from 'react';
import { withTheme } from 'styled-components';
import { Logo, LogoWrapper } from 'shared-components';

// Import Component Styles
import { Body, Main } from './styles';

export default withTheme(({ theme, children }) => (
  <Body>
    <LogoWrapper>
      <Logo src={theme.brand.logo} />
    </LogoWrapper>
    <Main>{children}</Main>
  </Body>
));
