import React from 'react';
// Import Global Components
import SectionOne from '../../../components/SectionOne';
import Text from '../../../components/Text';
// Import Translations
import { verifyEn } from '../../../i18n/verify';

export default () => (
  <SectionOne page="verify" label={verifyEn['verify-sectionOne-label']}>
    <Text
      page="verify"
      textFor="sectionOne-p1"
      text={verifyEn['verify-sectionOne-p1-text']}
    />
    <Text
      page="verify"
      textFor="sectionOne-p2"
      text={verifyEn['verify-sectionOne-p2-text']}
    />
  </SectionOne>
);
