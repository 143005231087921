import React from 'react';
import { FormattedMessage } from 'react-intl';
// Import Components
import Title from '../../components/Title';
import Text from '../../components/Text';
// Import Component Styles
import { LegalInfo, CardFeesTable } from './styles';
// Import Default Translations
import { tcCAEn } from '../../i18n/tc';

export default () => (
  <>
    <Title
      title={tcCAEn['tc-ca-agreement1-title']}
      titleFor="agreement1"
      page="tc-ca"
    />
    <LegalInfo>
      <Text
        text={tcCAEn['tc-ca-p1-text']}
        textFor="p1"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p1-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p2-text']}
        textFor="p2"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p2-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p3-text']}
        textFor="p3"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p3-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p4-text']}
        textFor="p4"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p4-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p5-text']}
        textFor="p5"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p5-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p6-text']}
        textFor="p6"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p6-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p7-text']}
        textFor="p7"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p7-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <CardFeesTable>
        <tr>
          <th>
            <FormattedMessage
              id="tc-ca-th1"
              description="Card Fees"
              defaultMessage={tcCAEn['tc-ca-th1']}
            />
          </th>
          <th>
            <FormattedMessage
              id="tc-ca-th2"
              description="Fee Amount"
              defaultMessage={tcCAEn['tc-ca-th2']}
            />
          </th>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="tc-ca-tr1-td1"
              description="Inactivity Fee"
              defaultMessage={tcCAEn['tc-ca-tr1-td1']}
            />
          </td>
          <td>
            <FormattedMessage
              id="tc-ca-tr1-td2"
              description="Inactivity Fee"
              defaultMessage={tcCAEn['tc-ca-tr1-td2']}
            />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="tc-ca-tr2-td1"
              description="Inactivity Fee"
              defaultMessage={tcCAEn['tc-ca-tr2-td1']}
            />
          </td>
          <td>
            <FormattedMessage
              id="tc-ca-tr2-td2"
              description="Inactivity Fee"
              defaultMessage={tcCAEn['tc-ca-tr2-td2']}
            />
          </td>
        </tr>
        <tr>
          <td>
            <FormattedMessage
              id="tc-ca-tr3-td1"
              description="Inactivity Fee"
              defaultMessage={tcCAEn['tc-ca-tr3-td1']}
            />
          </td>
          <td>
            <FormattedMessage
              id="tc-ca-tr3-td2"
              description="Inactivity Fee"
              defaultMessage={tcCAEn['tc-ca-tr3-td2']}
            />
          </td>
        </tr>
      </CardFeesTable>
      <Text text={tcCAEn['tc-ca-p8-text']} textFor="p8" page="tc-ca" />
      <Text
        text={tcCAEn['tc-ca-p9-text']}
        textFor="p9"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p9-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p10-text']}
        textFor="p10"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p10-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p11-text']}
        textFor="p11"
        page="tc-ca"
        values={{
          text2: tcCAEn['tc-ca-p11-bold-text'],
          bold: str => <b>{str}</b>,
          text1: tcCAEn['tc-ca-p11-bold-underline-text'],
          underline: str => (
            <b>
              <u>{str}</u>
            </b>
          )
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p12-text']}
        textFor="p12"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p12-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p13-text']}
        textFor="p13"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p13-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p14-text']}
        textFor="p14"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p14-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Title
        title={tcCAEn['tc-ca-agreement2-title']}
        titleFor="agreement2"
        page="tc-ca"
      />
      <Text
        text={tcCAEn['tc-ca-p15a-text']}
        textFor="p15a"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p15-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text text={tcCAEn['tc-ca-p15b-text']} textFor="p15b" page="tc-ca" />
      <Text
        text={tcCAEn['tc-ca-p16-text']}
        textFor="p16"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p16-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p17a-text']}
        textFor="p17a"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p17-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text text={tcCAEn['tc-ca-p17b-text']} textFor="p17b" page="tc-ca" />
      <Text
        text={tcCAEn['tc-ca-p18-text']}
        textFor="p18"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p18-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
      <Text
        text={tcCAEn['tc-ca-p19-text']}
        textFor="p19"
        page="tc-ca"
        values={{
          text: tcCAEn['tc-ca-p19-bold-text'],
          bold: str => <b>{str}</b>
        }}
      />
    </LegalInfo>
  </>
);
