// ****Esign US**** //
export const esignUSEn = {
  'esign-us-disclosure-title': 'E-Sign Act Disclosure',
  'esign-us-p1-text':
    'The ESIGN Act granted electronic signatures the same legal status as handwritten signatures throughout the U.S., greatly simplifying and expediting how organizations gather, track, and manage signatures, and approvals on agreements and documents of all kinds. Under the ESIGN Act, an electronic signature is defined as any electronic process associated with an agreement that indicates acceptance of that agreement. The ESIGN Act: ',
  'esign-us-li1-text':
    'Provides that any law with a requirement for a signature may be satisfied by an electronic signature.',
  'esign-us-li2-text':
    'Allows electronically executed agreements to be presented as evidence in court.',
  'esign-us-li3-text':
    'Prevents denial of legal effect, validity, or enforceability of an electronically signed document solely because it is in electronic form.',
  'esign-us-p2-text':
    'Under the ESIGN Act, an electronic signature is defined as “an electronic sound, symbol, or process attached to or logically associated with a contract or other record and executed or adopted by a person with the intent to sign the record.”',
  'esign-us-p3-text':
    'In the ESIGN and UETA (Uniform Electronic Transactions Act) acts, there are four major requirements for an electronic signature to be recognized as legal under U.S. law. These are the four requirements:',
  'esign-us-li4-text':
    'Intent to sign: eSignatures are only valid if the parties intended to sign the document.',
  'esign-us-li5-text':
    'Consent to do business electronically: Electronic records may only be used in transactions when consumers have agreed to use electronic records for the transaction and received UETA consumer consent disclosures.',
  'esign-us-li6-text':
    'Association of signature with the record: To qualify as an electronic signature, the system used for the transaction must keep a record that reflects the process the signature was created.',
  'esign-us-li7-text':
    'Record retention: Electronic signature records must be capable of retention and accurate reproduction for reference. All parties are entitled to a copy of the contract.',
  'esign-us-berkeley-stance-title': 'Berkeley’s approach to the ESIGN Act',
  'esign-us-p4-text': 'Virtual card programs',
  'esign-us-p5-text':
    'Berkeley will require an electronic signature for any virtual card programs that clients signs up to. Emails are sent from Berkeley to individual cardholders with a link and set up instructions.',
  'esign-us-p6-text':
    'Once the Cardholder clicks the link in the email it will open in their internet browser. They are required to input their First Name, Last Name, Email and Postal Code, and then click submit. Once submit is clicked, the client is brought to the Terms and Conditions of the card. They are required to read all the pages of the Terms and Conditions before checking the box that states, ‘I have read and agree to the Terms and Conditions’. They are also required to read the terms of the E-Sign Form (which will pop up in a new browser tab) before checking the box that states, ‘I have read and agree to the terms of the E-Sign Form’.',
  'esign-us-p7-text':
    'The E-Sign Disclosure covers all aspects of the ESIGN Act and is worded as follows:',
  'esign-us-li8-text':
    '<bold>{text}</bold> When you use a product or service to which this Disclosure applies, you agree that we may provide you with any Communications in electronic format, and that we may discontinue sending paper Communications to you, unless and until you withdraw your consent as described below. Your consent to receive electronic communications and transactions includes, but is not limited to:',
  'esign-us-li8-bold-text':
    'Scope of communications to be provided in electronic form.',
  'esign-us-li9-text':
    'All legal and regulatory disclosures and communications associated with [name of prepaid card] and any related products and services;',
  'esign-us-li10-text': 'Your Cardholder Agreement;',
  'esign-us-li11-text':
    'Notices about a change in the terms of your Cardholder Agreement;',
  'esign-us-li12-text': 'Privacy policies and notices;',
  'esign-us-li13-text': 'Error Resolution policies and notices;',
  'esign-us-li14-text':
    'Responses to claims filed in connection with your Card Account; and',
  'esign-us-li15-text':
    'Notices regarding insufficient funds or negative balances',
  'esign-us-li16-text':
    '<bold>{text}</bold> All Communications that we provide to you in electronic form will be provided online via a downloadable PDF form and/or visible display window.',
  'esign-us-li16-bold-text':
    'Method of providing communications to you in electronic form.',
  'esign-us-li17-text':
    '<bold>{text}</bold> You may withdraw your consent to receive Communications in electronic form at any time by contacting the company who provided you your prepaid card. If you do, your account will be terminated. We will send subsequent Communications to you in writing to the most current address we have for you in our records. We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your withdrawal. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.',
  'esign-us-li17-bold-text': 'How to withdraw consent.',
  'esign-us-li18-text':
    '<bold>{text}</bold> It is your responsibility to provide us with a true, accurate and complete e‐mail address, your contact information, and other information related to this Disclosure and your Card Account, and to maintain and update promptly any changes in this information. You can update information by logging into the cardholder website: www.gotomyprepaidcard.com.',
  'esign-us-li18-bold-text': 'How to update your records.',
  'esign-us-li19-text':
    '<bold>{text}</bold> In order to access, view, and retain electronic Communications that we make available to you, you must have:',
  'esign-us-li19-bold-text': 'Hardware and Software Requirements.',
  'esign-us-li20-text':
    'A PDF reader is required, the E-Sign disclosure must be provided in PDF format; OR a sample PDF document must be provided within the E-Sign disclosure.',
  'esign-us-li21-text':
    '<bold>{text}</bold> We will not send you a paper copy of any Communication, unless you request it, or we otherwise deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the electronic Communication to you. To request a paper copy, contact the company who provided you your prepaid card. We may charge you a service charge for the delivery of paper copies of certain Communications provided to you electronically pursuant to this authorization. See the fee schedule in your Agreement for details about this service charge. We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically.',
  'esign-us-li21-bold-text': 'Requesting paper copies.',
  'esign-us-li22-text':
    '<bold>{text}</bold> All Communications in either electronic or paper format from us to you will be considered "in writing." You should print or download for your records a copy of this Disclosure and any other Communication that is important to you.',
  'esign-us-li22-bold-text': 'Communications in writing.',
  'esign-us-li23-text':
    '<bold>{text}</bold> You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.',
  'esign-us-li23-bold-text': 'Federal Law.',
  'esign-us-li24-text':
    '<bold>{text}</bold> We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic Communications. We will provide you with notice of any such termination or change as required by law.',
  'esign-us-li24-bold-text': 'Termination/Changes.'
};

export const esignUSFr = {
  'esign-us-disclosure-title': '1',
  'esign-us-p1-text': '2',
  'esign-us-li1-text': '3',
  'esign-us-li2-text': '4',
  'esign-us-li3-text': '5',
  'esign-us-p2-text': '6',
  'esign-us-p3-text': '7',
  'esign-us-li4-text':
    'Intent to sign: eSignatures are only valid if the parties intended to sign the document.',
  'esign-us-li5-text':
    'Consent to do business electronically: Electronic records may only be used in transactions when consumers have agreed to use electronic records for the transaction and received UETA consumer consent disclosures.',
  'esign-us-li6-text':
    'Association of signature with the record: To qualify as an electronic signature, the system used for the transaction must keep a record that reflects the process the signature was created.',
  'esign-us-li7-text':
    'Record retention: Electronic signature records must be capable of retention and accurate reproduction for reference. All parties are entitled to a copy of the contract.',
  'esign-us-berkeley-stance-title': 'Berkeley’s approach to the ESIGN Act',
  'esign-us-p4-text': 'Virtual card programs',
  'esign-us-p5-text':
    'Berkeley will require an electronic signature for any virtual card programs that clients signs up to. Emails are sent from Berkeley to individual cardholders with a link and set up instructions.',
  'esign-us-p6-text':
    'Once the Cardholder clicks the link in the email it will open in their internet browser. They are required to input their First Name, Last Name, Email and Postal Code, and then click submit. Once submit is clicked, the client is brought to the Terms and Conditions of the card. They are required to read all the pages of the Terms and Conditions before checking the box that states, ‘I have read and agree to the Terms and Conditions’. They are also required to read the terms of the E-Sign Form (which will pop up in a new browser tab) before checking the box that states, ‘I have read and agree to the terms of the E-Sign Form’.',
  'esign-us-p7-text':
    'The E-Sign Disclosure covers all aspects of the ESIGN Act and is worded as follows:',
  'esign-us-li8-text':
    '<bold>{text}</bold> When you use a product or service to which this Disclosure applies, you agree that we may provide you with any Communications in electronic format, and that we may discontinue sending paper Communications to you, unless and until you withdraw your consent as described below. Your consent to receive electronic communications and transactions includes, but is not limited to:',
  'esign-us-li8-bold-text':
    'Scope of communications to be provided in electronic form.',
  'esign-us-li9-text':
    'All legal and regulatory disclosures and communications associated with [name of prepaid card] and any related products and services;',
  'esign-us-li10-text': 'Your Cardholder Agreement;',
  'esign-us-li11-text':
    'Notices about a change in the terms of your Cardholder Agreement;',
  'esign-us-li12-text': 'Privacy policies and notices;',
  'esign-us-li13-text': 'Error Resolution policies and notices;',
  'esign-us-li14-text':
    'Responses to claims filed in connection with your Card Account; and',
  'esign-us-li15-text':
    'Notices regarding insufficient funds or negative balances',
  'esign-us-li16-text':
    '<bold>{text}</bold> All Communications that we provide to you in electronic form will be provided online via a downloadable PDF form and/or visible display window.',
  'esign-us-li16-bold-text':
    'Method of providing communications to you in electronic form.',
  'esign-us-li17-text':
    '<bold>{text}</bold> You may withdraw your consent to receive Communications in electronic form at any time by contacting the company who provided you your prepaid card. If you do, your account will be terminated. We will send subsequent Communications to you in writing to the most current address we have for you in our records. We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your withdrawal. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.',
  'esign-us-li17-bold-text': 'How to withdraw consent.',
  'esign-us-li18-text':
    '<bold>{text}</bold> It is your responsibility to provide us with a true, accurate and complete e‐mail address, your contact information, and other information related to this Disclosure and your Card Account, and to maintain and update promptly any changes in this information. You can update information by logging into the cardholder website: www.gotomyprepaidcard.com.',
  'esign-us-li18-bold-text': 'How to update your records.',
  'esign-us-li19-text':
    '<bold>{text}</bold> In order to access, view, and retain electronic Communications that we make available to you, you must have:',
  'esign-us-li19-bold-text': 'Hardware and Software Requirements.',
  'esign-us-li20-text':
    'A PDF reader is required, the E-Sign disclosure must be provided in PDF format; OR a sample PDF document must be provided within the E-Sign disclosure.',
  'esign-us-li21-text':
    '<bold>{text}</bold> We will not send you a paper copy of any Communication, unless you request it, or we otherwise deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the electronic Communication to you. To request a paper copy, contact the company who provided you your prepaid card. We may charge you a service charge for the delivery of paper copies of certain Communications provided to you electronically pursuant to this authorization. See the fee schedule in your Agreement for details about this service charge. We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically.',
  'esign-us-li21-bold-text': 'Requesting paper copies.',
  'esign-us-li22-text':
    '<bold>{text}</bold> All Communications in either electronic or paper format from us to you will be considered "in writing." You should print or download for your records a copy of this Disclosure and any other Communication that is important to you.',
  'esign-us-li22-bold-text': 'Communications in writing.',
  'esign-us-li23-text':
    '<bold>{text}</bold> You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.',
  'esign-us-li23-bold-text': 'Federal Law.',
  'esign-us-li24-text':
    '<bold>{text}</bold> We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic Communications. We will provide you with notice of any such termination or change as required by law.',
  'esign-us-li24-bold-text': 'Termination/Changes.'
};

// ****Esign Canada**** //
export const esignCAEn = {
  'esign-ca-disclosure-title': 'E-Sign Disclosure',
  'esign-ca-p1-text':
    '<bold>{text}</bold> This Disclosure supplements and is to be construed in accordance with the terms contained in the Cardholder Agreement (<bold>{legalTerm1}</bold>) you received from the Bank. The words <bold>{legalTerm2}</bold> <bold>{legalTerm3}</bold> and <bold>{legalTerm4}</bold> refer to the Bank, the issuer of your [name of prepaid card]. The words <bold>{legalTerm5}</bold> and <bold>{legalTerm6}</bold> mean you, the individual(s) identified on the Card Account. As used in this Disclosure, <bold>{legalTerm7}</bold> means the [name of prepaid card] Card Account you have with us.',
  'esign-ca-p1-bold-text':
    'Note: This Electronic Communication Disclosure (“Disclosure”) applies to any and all communications or disclosures that we are legally required to provide to you in writing in connection with your prepaid card and any related products and services (“Communications”).',
  'esign-ca-p1-legalTerm1': '"Agreement"',
  'esign-ca-p1-legalTerm2': '"we,"',
  'esign-ca-p1-legalTerm3': '"us,"',
  'esign-ca-p1-legalTerm4': '"our"',
  'esign-ca-p1-legalTerm5': '"you"',
  'esign-ca-p1-legalTerm6': '"your"',
  'esign-ca-p1-legalTerm7': '"Card Account"',
  'esign-ca-li1-text':
    '<bold>{text}</bold> When you use a product or service to which this Disclosure applies, you agree that we may provide you with any Communications in electronic format, and that we may discontinue sending paper Communications to you, unless and until you withdraw your consent as described below. Your consent to receive electronic communications and transactions includes, but is not limited to:',
  'esign-ca-li-bold-text':
    'Scope of Communications to Be Provided in Electronic Form.',
  'esign-ca-li1-a-text':
    'All legal and regulatory disclosures and communications associated with [name of prepaid card] and any related products and services;',
  'esign-ca-li1-b-text': 'Your Cardholder Agreement;',
  'esign-ca-li1-c-text':
    'Notices about a change in the terms of your Cardholder Agreement;',
  'esign-ca-li1-d-text': 'Privacy policies and notices;',
  'esign-ca-li1-e-text': 'Error Resolution policies and notices;',
  'esign-ca-li1-f-text':
    'Responses to claims filed in connection with your Card Account; and',
  'esign-ca-li1-g-text':
    'Notices regarding insufficient funds or negative balances',
  'esign-ca-li2-text':
    '<bold>{text}</bold> All Communications that we provide to you in electronic form will be provided online via a downloadable PDF form and/or visible display window.',
  'esign-ca-li2-bold-text':
    'Method of Providing Communications to You in Electronic Form.',
  'esign-ca-li3-text':
    '<bold>{text}</bold> You may withdraw your consent to receive Communications in electronic form at any time by contacting the company who provided you your prepaid card. If you do, your account will be terminated. We will send subsequent Communications to you in writing to the most current address we have for you in our records. We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your withdrawal. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.',
  'esign-ca-li3-bold-text': 'How to Withdraw Consent.',
  'esign-ca-li4-text':
    '<bold>{text}</bold> It is your responsibility to provide us with a true, accurate and complete e‐mail address, your contact information, and other information related to this Disclosure and your Card Account, and to maintain and update promptly any changes in this information. You can update information by logging into the cardholder website: www.gotomyprepaidcard.com.',
  'esign-ca-li4-bold-text': 'How to Update Your Records.',
  'esign-ca-li5-text':
    '<bold>{text}</bold> In order to access, view, and retain electronic Communications that we make available to you, you must have:',
  'esign-ca-li5-bold-text': 'Hardware and Software Requirements.',
  'esign-ca-li5-a-text':
    'A PDF reader is required, the E-Sign disclosure must be provided in PDF format; OR a sample PDF document must be provided within the E-Sign disclosure.',
  'esign-ca-li6-text':
    '<bold>{text}</bold> We will not send you a paper copy of any Communication, unless you request it or we otherwise deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the electronic Communication to you. To request a paper copy, contact the company who provided you your prepaid card. We may charge you a service charge for the delivery of paper copies of certain Communications provided to you electronically pursuant to this authorization. See the fee schedule in your Agreement for details about this service charge. We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically.',
  'esign-ca-li6-bold-text': 'Requesting Paper Copies.',
  'esign-ca-li7-text':
    '<bold>{text}</bold> All Communications in either electronic or paper format from us to you will be considered "in writing." You should print or download for your records a copy of this Disclosure and any other Communication that is important to you.',
  'esign-ca-li7-bold-text': 'Communications in Writing.',
  'esign-ca-li8-text':
    '<bold>{text}</bold> You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.',
  'esign-ca-li8-bold-text': 'Federal Law.',
  'esign-ca-li9-text':
    '<bold>{text}</bold> We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic Communications. We will provide you with notice of any such termination or change as required by law.',
  'esign-ca-li9-bold-text': 'Termination/Changes.'
};

export const esignCAFr = {
  'esign-ca-disclosure-title': 'E-Sign Disclosure',
  'esign-ca-p1-text':
    '<bold>{text}</bold> This Disclosure supplements and is to be construed in accordance with the terms contained in the Cardholder Agreement (<bold>{legalTerm1}</bold>) you received from the Bank. The words <bold>{legalTerm2}</bold> <bold>{legalTerm3}</bold> and <bold>{legalTerm4}</bold> refer to the Bank, the issuer of your [name of prepaid card]. The words <bold>{legalTerm5}</bold> and <bold>{legalTerm6}</bold> mean you, the individual(s) identified on the Card Account. As used in this Disclosure, <bold>{legalTerm7}</bold> means the [name of prepaid card] Card Account you have with us.',
  'esign-ca-p1-bold-text':
    'Note: This Electronic Communication Disclosure (“Disclosure”) applies to any and all communications or disclosures that we are legally required to provide to you in writing in connection with your prepaid card and any related products and services (“Communications”).',
  'esign-ca-p1-legalTerm1': '"Agreement"',
  'esign-ca-p1-legalTerm2': '"we,"',
  'esign-ca-p1-legalTerm3': '"us,"',
  'esign-ca-p1-legalTerm4': '"our"',
  'esign-ca-p1-legalTerm5': '"you"',
  'esign-ca-p1-legalTerm6': '"your"',
  'esign-ca-p1-legalTerm7': '"Card Account"',
  'esign-ca-li1-text':
    '<bold>{text}</bold> When you use a product or service to which this Disclosure applies, you agree that we may provide you with any Communications in electronic format, and that we may discontinue sending paper Communications to you, unless and until you withdraw your consent as described below. Your consent to receive electronic communications and transactions includes, but is not limited to:',
  'esign-ca-li-bold-text':
    'Scope of Communications to Be Provided in Electronic Form.',
  'esign-ca-li1-a-text':
    'All legal and regulatory disclosures and communications associated with [name of prepaid card] and any related products and services;',
  'esign-ca-li1-b-text': 'Your Cardholder Agreement;',
  'esign-ca-li1-c-text':
    'Notices about a change in the terms of your Cardholder Agreement;',
  'esign-ca-li1-d-text': 'Privacy policies and notices;',
  'esign-ca-li1-e-text': 'Error Resolution policies and notices;',
  'esign-ca-li1-f-text':
    'Responses to claims filed in connection with your Card Account; and',
  'esign-ca-li1-g-text':
    'Notices regarding insufficient funds or negative balances',
  'esign-ca-li2-text':
    '<bold>{text}</bold> All Communications that we provide to you in electronic form will be provided online via a downloadable PDF form and/or visible display window.',
  'esign-ca-li2-bold-text':
    'Method of Providing Communications to You in Electronic Form.',
  'esign-ca-li3-text':
    '<bold>{text}</bold> You may withdraw your consent to receive Communications in electronic form at any time by contacting the company who provided you your prepaid card. If you do, your account will be terminated. We will send subsequent Communications to you in writing to the most current address we have for you in our records. We will not impose any fee to process the withdrawal of your consent to receive electronic Communications. Any withdrawal of your consent to receive electronic Communications will be effective only after we have a reasonable period of time to process your withdrawal. In the meantime, you will continue to receive Communications in electronic form. If you withdraw your consent, the legal validity and enforceability of prior Communications delivered in electronic form will not be affected.',
  'esign-ca-li3-bold-text': 'How to Withdraw Consent.',
  'esign-ca-li4-text':
    '<bold>{text}</bold> It is your responsibility to provide us with a true, accurate and complete e‐mail address, your contact information, and other information related to this Disclosure and your Card Account, and to maintain and update promptly any changes in this information. You can update information by logging into the cardholder website: www.gotomyprepaidcard.com.',
  'esign-ca-li4-bold-text': 'How to Update Your Records.',
  'esign-ca-li5-text':
    '<bold>{text}</bold> In order to access, view, and retain electronic Communications that we make available to you, you must have:',
  'esign-ca-li5-bold-text': 'Hardware and Software Requirements.',
  'esign-ca-li5-a-text':
    'A PDF reader is required, the E-Sign disclosure must be provided in PDF format; OR a sample PDF document must be provided within the E-Sign disclosure.',
  'esign-ca-li6-text':
    '<bold>{text}</bold> We will not send you a paper copy of any Communication, unless you request it or we otherwise deem it appropriate to do so. You can obtain a paper copy of an electronic Communication by printing it yourself or by requesting that we mail you a paper copy, provided that such request is made within a reasonable time after we first provided the electronic Communication to you. To request a paper copy, contact the company who provided you your prepaid card. We may charge you a service charge for the delivery of paper copies of certain Communications provided to you electronically pursuant to this authorization. See the fee schedule in your Agreement for details about this service charge. We reserve the right, but assume no obligation, to provide a paper (instead of electronic) copy of any Communication that you have authorized us to provide electronically.',
  'esign-ca-li6-bold-text': 'Requesting Paper Copies.',
  'esign-ca-li7-text':
    '<bold>{text}</bold> All Communications in either electronic or paper format from us to you will be considered "in writing." You should print or download for your records a copy of this Disclosure and any other Communication that is important to you.',
  'esign-ca-li7-bold-text': 'Communications in Writing.',
  'esign-ca-li8-text':
    '<bold>{text}</bold> You acknowledge and agree that your consent to electronic Communications is being provided in connection with a transaction affecting interstate commerce that is subject to the federal Electronic Signatures in Global and National Commerce Act, and that you and we both intend that the Act apply to the fullest extent possible to validate our ability to conduct business with you by electronic means.',
  'esign-ca-li8-bold-text': 'Federal Law.',
  'esign-ca-li9-text':
    '<bold>{text}</bold> We reserve the right, in our sole discretion, to discontinue the provision of your electronic Communications, or to terminate or change the terms and conditions on which we provide electronic Communications. We will provide you with notice of any such termination or change as required by law.',
  'esign-ca-li9-bold-text': 'Termination/Changes.'
};
