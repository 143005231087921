import React, {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, ExternalLink} from 'shared-components';
import {post} from 'shared-components/src/utils/http';
import {verifyEn} from '../../../i18n/verify';
import agreement from '../assets/T&C Virtual 2022 US.pdf';

const BaseTermsLink = () => (
  <Link
    to={agreement}
    type="primary"
    target="_blank"
  >
    <FormattedMessage
      id="verify-tc-link"
      description="Link for tc"
      defaultMessage={verifyEn['verify-tc-link']}
    />
  </Link>
);

// eslint-disable-next-line react/prop-types
export default ({accountId}) => {
  const [link, setLink] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const fetchLink = async () => {
    try {
      const res = await post('/api/v1/cardholder_portal/cardholder_agreement', {
        account_id: accountId,
      });

      if (res.data && res.data.cardholder_agreement_url) {
        setLink(res.data.cardholder_agreement_url);
      }
    } catch (e) {
      setIsError(true);
    }
  };

  if (!isLoaded) {
    fetchLink();
    setIsLoaded(true);
  }

  if (!link || isError) {
    return <BaseTermsLink />;
  }

  return (
    <ExternalLink
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <FormattedMessage
        id="verify-tc-link"
        description="Link for tc"
        defaultMessage={verifyEn['verify-tc-link']}
      />
    </ExternalLink>
  )
  ;
};