import {
  issuerStatementEn,
  issuerStatementFr,
} from 'shared-components/src/components/IssuerStatement/text';
import TRANSLATIONS from 'shared-components/src/i18n/translations';
import { verifyEn, verifyFr } from './verify';
import { activatedEn, activatedFr } from './activated';
import { esignCAEn, esignCAFr, esignUSEn, esignUSFr } from './esign';
import { tcCAEn, tcCAFr, tcUSEn, tcUSFr } from './tc';

export default {
  en: {
    'header-locale-text': 'Change Language',
    'header-en-button': 'EN',
    'header-fr-button': 'FR',
    'main-not-found-title': 'Sorry! This page could not be found.',
    ...verifyEn,
    ...activatedEn,
    ...esignCAEn,
    ...esignUSEn,
    ...issuerStatementEn,
    ...tcCAEn,
    ...tcUSEn,
    ...TRANSLATIONS.en,
  },
  fr: {
    'header-locale-text': 'changer de langue',
    'header-en-button': 'EN',
    'header-fr-button': 'FR',
    'main-not-found-title': 'Sorry! This page could not be found.',
    ...verifyFr,
    ...activatedFr,
    ...esignCAFr,
    ...esignUSFr,
    ...issuerStatementFr,
    ...tcCAFr,
    ...tcUSFr,
    ...TRANSLATIONS.fr,
  },
};
