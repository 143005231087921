import React from 'react';
// Import Components
import Title from '../../components/Title';
import Text from '../../components/Text';
// Import Default Translations
import { esignUSEn } from '../../i18n/esign';
// Import Component Styles
import { NumberListItem, BulletListItem, LegalInfo } from './styles';

export default () => (
  <>
    <Title
      title={esignUSEn['esign-us-disclosure-title']}
      titleFor="disclosure"
      page="esign-us"
    />
    <LegalInfo>
      <Text text={esignUSEn['esign-us-p1-text']} textFor="p1" page="esign-us" />
      <ul>
        <BulletListItem>
          <Text
            text={esignUSEn['esign-us-li1-text']}
            textFor="li1"
            page="esign-us"
          />
        </BulletListItem>
        <BulletListItem>
          <Text
            text={esignUSEn['esign-us-li2-text']}
            textFor="li2"
            page="esign-us"
          />
        </BulletListItem>
        <BulletListItem>
          <Text
            text={esignUSEn['esign-us-li3-text']}
            textFor="li3"
            page="esign-us"
          />
        </BulletListItem>
      </ul>
      <Text text={esignUSEn['esign-us-p2-text']} textFor="p2" page="esign-us" />
      <Text text={esignUSEn['esign-us-p3-text']} textFor="p3" page="esign-us" />
      <ol>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li4-text']}
            textFor="li4"
            page="esign-us"
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li5-text']}
            textFor="li5"
            page="esign-us"
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li6-text']}
            textFor="li6"
            page="esign-us"
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li7-text']}
            textFor="li7"
            page="esign-us"
          />
        </NumberListItem>
      </ol>
      <Title
        title={esignUSEn['esign-us-berkeley-stance-title']}
        titleFor="berkeley-stance"
        page="esign-us"
      />
      <b>
        <Text
          text={esignUSEn['esign-us-p4-text']}
          textFor="p4"
          page="esign-us"
        />
      </b>
      <Text text={esignUSEn['esign-us-p5-text']} textFor="p5" page="esign-us" />
      <Text text={esignUSEn['esign-us-p6-text']} textFor="p6" page="esign-us" />
      <Text text={esignUSEn['esign-us-p7-text']} textFor="p7" page="esign-us" />
      <ol>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li8-text']}
            textFor="li8"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li8-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
          <ul>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li9-text']}
                textFor="li9"
                page="esign-us"
              />
            </BulletListItem>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li10-text']}
                textFor="li10"
                page="esign-us"
              />
            </BulletListItem>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li11-text']}
                textFor="li11"
                page="esign-us"
              />
            </BulletListItem>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li12-text']}
                textFor="li12"
                page="esign-us"
              />
            </BulletListItem>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li13-text']}
                textFor="li13"
                page="esign-us"
              />
            </BulletListItem>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li14-text']}
                textFor="li14"
                page="esign-us"
              />
            </BulletListItem>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li15-text']}
                textFor="li15"
                page="esign-us"
              />
            </BulletListItem>
          </ul>
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li16-text']}
            textFor="li16"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li16-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li17-text']}
            textFor="li17"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li17-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li18-text']}
            textFor="li18"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li18-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li19-text']}
            textFor="li19"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li19-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
          <ul>
            <BulletListItem>
              <Text
                text={esignUSEn['esign-us-li20-text']}
                textFor="li20"
                page="esign-us"
              />
            </BulletListItem>
          </ul>
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li21-text']}
            textFor="li21"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li21-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li22-text']}
            textFor="li22"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li22-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li23-text']}
            textFor="li23"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li23-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
        <NumberListItem>
          <Text
            text={esignUSEn['esign-us-li24-text']}
            textFor="li24"
            page="esign-us"
            values={{
              text: esignUSEn['esign-us-li24-bold-text'],
              bold: str => <b>{str}</b>
            }}
          />
        </NumberListItem>
      </ol>
    </LegalInfo>
  </>
);
