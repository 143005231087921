import React from 'react';
import { Switch } from 'react-router-dom';
// Import Shared Components
import { ErrorBoundary } from 'shared-components';
// Import Layouts
import Base from '../layouts/Base';
import Body from '../layouts/Body';
import Route from '../layouts/Route';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import NotFound from '../layouts/NotFound';
import Shell from '../layouts/Shell';
// Import Route Components
import Verify from './Verify';
import TC from './TermsAndConditions';
import Esign from './Esign';
import Activated from './Activated';

import { errorLogger } from '../utils/errors';

export default props => (
  <Shell>
    <Base>
      <Header {...props} />
      <Body>
        <ErrorBoundary errorLogger={errorLogger}>
          <Switch>
            <Route exact path="/activate/:emailLink" component={Verify} />
            <Route exact path="/terms" component={TC} />
            <Route exact path="/esign" component={Esign} />
            <Route exact path="/activated" component={Activated} />
            <Route component={NotFound} />
          </Switch>
        </ErrorBoundary>
      </Body>
      <Footer />
    </Base>
  </Shell>
);
