import React from 'react';
import PropTypes from 'prop-types';

import { Base, BaseWrapper } from './styles';

const BaseLayout = ({ children }) => (
  <BaseWrapper>
    <Base>{children}</Base>
  </BaseWrapper>
);

BaseLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.array.isRequired
};

export default BaseLayout;