import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Utils
import { formatExpiryDate } from 'shared-components/src/utils/formatDateTime';
// Import Global Components
import SectionOne from '../../../components/SectionOne';
// Import Local Components
import CardInfo from './CardInfo';
// Import Translations
import { activatedEn } from '../../../i18n/activated';

const SectionOneWrapper = ({
  name,
  address,
  number,
  expiry,
  cardSecurityCode,
  ...props
}) => (
  <SectionOne
    page="activated"
    label="Personal Card Information:"
    name={name}
    number={number}
    expiry={expiry}
    {...props}
  >
    <CardInfo
      labelFor="name"
      label={activatedEn['activated-name-label']}
      info={name}
    />
    <CardInfo
      labelFor="address"
      label={activatedEn['activated-address-label']}
      info={address}
    />
    <CardInfo
      labelFor="cardNumber"
      label={activatedEn['activated-cardNumber-label']}
      info={number}
    />
    <CardInfo
      labelFor="expiry"
      label={activatedEn['activated-expiry-label']}
      info={formatExpiryDate(expiry)}
    />
    <CardInfo
      labelFor="cvv"
      label={activatedEn['activated-cvv-label']}
      info={cardSecurityCode}
    />
  </SectionOne>
);

SectionOneWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  expiry: PropTypes.string.isRequired,
  cardSecurityCode: PropTypes.string.isRequired,
};

export default SectionOneWrapper;
