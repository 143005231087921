import { useState, useEffect } from 'react';

export default (initalUrl, initalData = null) => {
  const [data, setData] = useState(initalData);
  const [url, setUrl] = useState(initalUrl);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        const res = await fetch(url);
        const data = await res.json();
        setData(data);
      } catch (err) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    // Only fetch data is the URL exists
    if (url) {
      fetchData();
    }
  }, [url]);

  return [{ data, isLoading, isError }, setUrl];
};
