import React from 'react';
// Import Global Components
import Title from '../components/Title';
import Main from './Main';

export default () => (
  <Main>
    <Title
      title="Sorry! This page could not be found"
      titleFor="not-found"
      page="main"
    />
  </Main>
);
