import React from 'react';
// import { Redirect, Route } from 'react-router-dom'
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
// import auth from 'shared-components/src/utils/auth'

const RouteWrapper = ({ priv, ...props }) => {
  return <Route {...props} />;
};

RouteWrapper.propTypes = {
  priv: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  priv: false,
};

export default RouteWrapper;
