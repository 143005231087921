import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from 'shared-components';
import PropTypes from 'prop-types';

const VCTitle = ({ title, titleFor, page }) => (
  <Title>
    <FormattedMessage
      id={`${page}-${titleFor}-title`}
      description={`Title for ${title}`}
      defaultMessage={`${title}`}
    />
  </Title>
);

VCTitle.propTypes = {
  page: PropTypes.string.isRequired,
  titleFor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default VCTitle;
