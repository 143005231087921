export const activatedEn = {
  'activated-main-title': 'Your virtual card is now activated!',
  'activated-sectionOne-label': 'Personal Card Information:',
  'activated-name-label': 'Name:',
  'activated-address-label': 'Address:',
  'activated-cardNumber-label': 'Card Number:',
  'activated-expiry-label': 'Expiration Date:',
  'activated-accessCode-label': 'Access Code:',
  'activated-cvv-label': '3 Digit Code (CVV):',
  'activated-balance-label': 'Balance:',
  'activated-sectionTwo-p1-text':
    'Your card is now activated and ready to be used! Before using, make sure to register your virtual card.',
  'activated-sectionTwo-p2-text': 'How do I use my card?',
  'activated-sectionTwo-li1-text':
    'Print or save this page for your records, these are your card details.',
  'activated-sectionTwo-li2-text':
    'Make purchases online or over the phone, anywhere Visa/Mastercard is accepted.',
  'activated-sectionTwo-li3-text':
    'Use your Personal Card Information when making purchases.',
  'activated-sectionTwo-li4-text':
    'Check your balance and view your transaction history on the Cardholder site.',
  'activated-sectionTwo-p4-text': 'Why Register my card?',
  'activated-sectionTwo-li5-text':
    'Check card balance on Cardholder site.',
  'activated-sectionTwo-li6-text':
    'View transaction history on the Cardholder site.',
  'activated-sectionTwo-li7-text':
    'Update name and address associated to card.',
  'activated-register-button': 'Register Card',
  'activated-print-button': 'Print this Page'
};

export const activatedFr = {
  'activated-main-title':
    'Votre carte est maintenant activée et prête à être utilisée!',
  'activated-sectionOne-label': 'Informations sur la carte:',
  'activated-name-label': 'Nom',
  'activated-address-label': 'Adresse',
  'activated-cardNumber-label': 'Numéro de la carte',
  'activated-expiry-label': 'Date d\'expiration',
  'activated-accessCode-label': 'Code d\'accès',
  'activated-cvv-label': 'Code à 3 chiffres (CVV)',
  'activated-balance-label': 'Solde sur la carte',
  'activated-sectionTwo-p1-text':
    'Avant d’utiliser votre carte virtuelle, n’oubliez pas de l’enregistrer.',
  'activated-sectionTwo-p2-text': 'Comment utiliser ma carte?',
  'activated-sectionTwo-li1-text':
    'Conservez une version imprimée ou téléchargée de cette page, elle reprend les détails de votre carte.',
  'activated-sectionTwo-li2-text':
    'Faites vos achats en ligne ou par téléphone, chez tous les marchands qui acceptent Visa.',
  'activated-sectionTwo-li3-text':
    'Utilisez les Données de votre carte personnelle lors de vos achats.',
  'activated-sectionTwo-li4-text':
    'Vérifiez votre solde et consultez l\'historique de vos transactions sur le site du titulaire de carte.',
  'activated-sectionTwo-p3-text':
    'Si vous avez oublié votre PIN, veuillez composer le 1-855-310-3383 et suivre les instructions enregistrées.',
  'activated-sectionTwo-p4-text': 'Pourquoi enregistrer ma carte?',
  'activated-sectionTwo-li5-text':
    'Vous pouvez consulter le solde de votre carte sur le site du titulaire de carte.',
  'activated-sectionTwo-li6-text':
    'Vous pouvez voir l’historique de vos transactions sur le site du titulaire de carte.',
  'activated-sectionTwo-li7-text':
    'Vous pouvez modifier le nom et l’adresse associés à la carte.',
  'activated-register-button': 'Enregistrer la carte',
  'activated-print-button': 'Imprimer cette page'
};
