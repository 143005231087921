import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 10px;
`;

export const FormGroup = styled.fieldset`
  border: 0;
`;

export const RedText = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.red};
  margin: 13px 0 5px 0;
`;

export const LargeText = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

export const ActivatedText = styled(LargeText)`
  color: ${({ theme }) => theme.colors.green};
`;

export const ActivatedImage = styled.img`
  height: 204px;
  width: 218px;
`;

export const ListItem = styled.li`
  list-style: disc;
  margin-left: 15px;
`;

export const VirtualCardSection = styled.section`
  display:flex
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;

  button {
    width: 100%;
  }

  & > * > div {
    margin-bottom: 10px;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > * {
    width: 50%;
  }

  label {
    margin-bottom: 0;
  }
`;
